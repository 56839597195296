import React from "react";
import BtnLink from "../../_App/Buttons/BtnLink/BtnLink";

function MainBanner() {
  return (
    <div className="main-banner-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-12 ">
            <div className="main-banner-content">
              <div
                className="tag"
                data-aos="fade-right"
                data-aos-delay="100"
                data-aos-duration="1200"
                data-aos-once="true"
              >
                <img
                  src="/images/main-banner/banner-one/tag-icon.png"
                  alt="IP Atlas small icon"
                />
                # IP Location Mapping Service
              </div>

              <h1
                data-aos="fade-right"
                data-aos-delay="200"
                data-aos-duration="1200"
                data-aos-once="true"
              >
                IP Geolocation API, IP Lookup and Proxy Databases
              </h1>

              <p
                data-aos="fade-right"
                data-aos-delay="300"
                data-aos-duration="1200"
                data-aos-once="true"
              >
                Discover precise location insights with our IP Location Mapping service,
                empowering your digital experiences with accuracy and efficiency. <b>We make integration with our system quick and easy</b>, 
                without the need for any special knowledge or skills, using the option that best suits you.
              </p>

              <div className="banner-btn">
                <div
                  data-aos="fade-left"
                  data-aos-delay="400"
                  data-aos-duration="1200"
                  data-aos-once="true"
                >
                  <BtnLink link="/services">Read More</BtnLink>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-7 col-md-12 h-50 ">
            <div className="main-banner-animation-image">
              {/* Do not remove the div, is required! */}
              <div>
                <img
                  src="/images/main-banner/banner-one/main-pic.png"
                  alt="API, IP Lookup and IP Proxy Databases"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainBanner;
