import React from "react";
import { Route, Routes } from "react-router-dom";
import ResourceSideBar from "./ResourceSideBar";
import { GetApiAuthenticationContent, GetApiIpLookup, GetApiDatabasePinning } from "./Content/ApiContent";
import { GetJavaReaderSDK, GetIPLookup } from "./Content/IPLookupDBContent";
import { GetProxyJavaReaderSDK, GetProxyIPLookup } from "./Content/IPProxyDBContent";
import { GetCliInstallation, GetCliCommands } from "./Content/CliContent";
import { GetResourcesOverview } from "./Content/ResourcesContent";

const ResourceContent = () => {
  return (
    <>
      <div className="services-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12">
              <ResourceSideBar />
            </div>

            <div className="col-lg-9 col-md-12">
              <Routes>
                <Route exact path="/" element={ <GetResourcesOverview /> } />
                <Route exact path="/api-authentication" element={ <GetApiAuthenticationContent /> } />
                <Route exact path="/api-iplookup" element={ <GetApiIpLookup /> } />
                <Route exact path="/api-databasepinning" element={ <GetApiDatabasePinning /> } />

                <Route exact path="/ipdatabase-sdk" element={ <GetJavaReaderSDK /> } />
                <Route exact path="/ipdatabase-lookup" element={ <GetIPLookup /> } />

                <Route exact path="/dbproxy-sdk" element={ <GetProxyJavaReaderSDK /> } />
                <Route exact path="/dbproxy-lookup" element={ <GetProxyIPLookup /> } />

                <Route exact path="/cli-installation" element={ <GetCliInstallation /> } />
                <Route exact path="/cli-commands" element={ <GetCliCommands /> } />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResourceContent;
