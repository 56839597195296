import React from "react";

const TechSupport = () => {
  return (
    <>
      <div className="support-area">
        <div className="container">
          <div
            className="support-content"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-duration="1200"
            data-aos-once="false"
          >
            <div className="tag">
              <img src={process.env.PUBLIC_URL + '/images/experiences/tag-icon.png'} alt="IP Atlass small icon" />
            </div>

            <h3>Technical Support</h3>
            <p>If you need faster response times and hight priority escalation, we offer an additional technical support service.
            </p>
            <p>
            Contact Us for more information. 
              </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TechSupport;
