import React from "react";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";

function FaqAccordion() {
  return (
    <div className="container">
      <div className="faq-accordion accordion-box">
        <Accordion allowZeroExpanded>
          <AccordionItem uuid="a">
            <AccordionItemHeading>
              <AccordionItemButton>
                Why Are Consultants Important?
              </AccordionItemButton>
            </AccordionItemHeading>

            <AccordionItemPanel>
              <p>
                The IT industry offers a sea of options, from platforms,
                programming languages, methodologies, technologies, tools, and
                more. IT consulting services are important because they play a
                vital role in businesses by covering the management,
                implementation, deployment, and maintenance of an IT
                infrastructure. IT industry offers a sea of options, from
                platforms, programming languages, methodologies, technologies,
                tools, and more.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem uuid="b">
            <AccordionItemHeading>
              <AccordionItemButton>
                What is The Purpose of a Consultant?
              </AccordionItemButton>
            </AccordionItemHeading>

            <AccordionItemPanel>
              <p>
                The IT industry offers a sea of options, from platforms,
                programming languages, methodologies, technologies, tools, and
                more. IT consulting services are important because they play a
                vital role in businesses by covering the management,
                implementation, deployment, and maintenance of an IT
                infrastructure. IT industry offers a sea of options, from
                platforms, programming languages, methodologies, technologies,
                tools, and more.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem uuid="c">
            <AccordionItemHeading>
              <AccordionItemButton>
                What Attracts You To The Role of a Consultant?
              </AccordionItemButton>
            </AccordionItemHeading>

            <AccordionItemPanel>
              <p>
                The IT industry offers a sea of options, from platforms,
                programming languages, methodologies, technologies, tools, and
                more. IT consulting services are important because they play a
                vital role in businesses by covering the management,
                implementation, deployment, and maintenance of an IT
                infrastructure. IT industry offers a sea of options, from
                platforms, programming languages, methodologies, technologies,
                tools, and more.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem uuid="d">
            <AccordionItemHeading>
              <AccordionItemButton>
                What Are The Advantages of Being a Consultant?
              </AccordionItemButton>
            </AccordionItemHeading>

            <AccordionItemPanel>
              <p>
                The IT industry offers a sea of options, from platforms,
                programming languages, methodologies, technologies, tools, and
                more. IT consulting services are important because they play a
                vital role in businesses by covering the management,
                implementation, deployment, and maintenance of an IT
                infrastructure. IT industry offers a sea of options, from
                platforms, programming languages, methodologies, technologies,
                tools, and more.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem uuid="e">
            <AccordionItemHeading>
              <AccordionItemButton>
                Is Consulting a Good Career?
              </AccordionItemButton>
            </AccordionItemHeading>

            <AccordionItemPanel>
              <p>
                The IT industry offers a sea of options, from platforms,
                programming languages, methodologies, technologies, tools, and
                more. IT consulting services are important because they play a
                vital role in businesses by covering the management,
                implementation, deployment, and maintenance of an IT
                infrastructure. IT industry offers a sea of options, from
                platforms, programming languages, methodologies, technologies,
                tools, and more.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem uuid="f">
            <AccordionItemHeading>
              <AccordionItemButton>
                How is Working in Consulting?
              </AccordionItemButton>
            </AccordionItemHeading>

            <AccordionItemPanel>
              <p>
                The IT industry offers a sea of options, from platforms,
                programming languages, methodologies, technologies, tools, and
                more. IT consulting services are important because they play a
                vital role in businesses by covering the management,
                implementation, deployment, and maintenance of an IT
                infrastructure. IT industry offers a sea of options, from
                platforms, programming languages, methodologies, technologies,
                tools, and more.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
}

export default FaqAccordion;
