import React from 'react';
import CodeSnippet from "../../../_App/CodeSnippet/CodeSnippet";
import CodeSnippetTab from "../../../_App/CodeSnippet/CodeSnippetTab";

const tabs = [
  { id: 'tab1', label: 'Tab 1', content: <code></code> },
  { id: 'tab2', label: 'Tab 2', content: <p>This is the content of Tab 2.</p> },
  { id: 'tab3', label: 'Tab 3', content: <p>This is the content of Tab 3.</p> },
];

export const GetApiAuthenticationContent = () => {
    return (
        <>
            <div className="services-details-overview-content">
                <h3>API Authenticate</h3>
                <p>
                  This article will guide you through the process of authenticating with an IP Geolocation REST API using an API key. Authenticating with an IP Geolocation REST API is a straightforward process that involves generating an API key via the Portal and including that key in your HTTP header requests. By following the steps outlined in this article, you can securely and effectively authenticate your requests, enabling you to utilize the geolocation services in your applications.
                </p>
  
                <h5>Sign Up and Access the Portal</h5>
                <ul>
                  <li>First, sign up for an account on the IpAtlas Portal.</li>
                  <li>After logging in, navigate to the Portal where you can manage your account and API keys.</li>
                </ul>

                <h5>Generate an API Key</h5>
                <ul>
                  <li>In the Portal, locate the section for API key management.</li>
                  <li>Click on the option to generate a new API key.</li>
                  <li>Save this key securely, as it will be used to authenticate your requests.</li>
                </ul>

                <h5>Store API key in a secure place</h5>
                <ul>
                  <li>When generating an API key, store it in a secure place and do not share the API key with other users as this can lead to throttling of your requests.</li>
                </ul>

                <h5>Using the API Key in Requests</h5>
                <ul>
                  <li>To authenticate your requests to the IP Geolocation REST API, include the API key in your HTTP requests Authentication header.</li>
                </ul>

                <p>
                  Here’s how you can use the API key in a request to the IP Geolocation REST API.
                </p>

                <CodeSnippet>
                  <CodeSnippetTab title="Java">
                      <pre><code>
{`String baseEndpoint = "http://your.endpoint";
String ipAddress = "8.8.8.8"; // Replace with the IP address you want to lookup
String endpoint = baseEndpoint + "/api/v1/lookup/" + ipAddress;

URL url = new URL(endpoint);
HttpURLConnection conn = (HttpURLConnection) url.openConnection();
conn.setRequestMethod("GET");
conn.setRequestProperty("Authorization", "Bearer YOUR_AUTH_TOKEN");

BufferedReader in = new BufferedReader(new InputStreamReader(conn.getInputStream()));
String inputLine;
StringBuffer response = new StringBuffer();

while ((inputLine = in.readLine()) != null) {
    response.append(inputLine);
}
in.close();

System.out.println("Response from server:");
System.out.println(response.toString());
`}
                      </code></pre>
                    </CodeSnippetTab>
                    <CodeSnippetTab title="JavaScript">
                    <pre>
  <code>
    {`const https = require('https');

const ipAddress = '8.8.8.8'; // Replace with the IP address you want to lookup
const endpoint = \`http://your.endpoint/api/v1/lookup/\${ipAddress}\`;
const options = {
    method: 'GET',
    headers: {
        'Authorization': 'Bearer YOUR_AUTH_TOKEN'
    }
};

https.get(endpoint, options, (response) => {
    let data = '';

    response.on('data', (chunk) => {
        data += chunk;
    });

    response.on('end', () => {
        console.log('Response from server:');
        console.log(JSON.parse(data));
    });
}).on('error', (error) => {
    console.error('Error:', error.message);
});
`}
  </code>
</pre>
                    </CodeSnippetTab>
                    <CodeSnippetTab title="C#">
                      <pre><code>
{`String baseEndpoint = "http://your.endpoint";
string ipAddress = "8.8.8.8"; // Replace with the IP address you want to lookup
string endpoint = $"{baseEndpoint}/api/v1/lookup/{ipAddress}";

HttpWebRequest request = (HttpWebRequest)WebRequest.Create(endpoint);
request.Method = "GET";
request.Headers.Add("Authorization", "Bearer YOUR_AUTH_TOKEN");

using (HttpWebResponse response = (HttpWebResponse)request.GetResponse())
using (Stream stream = response.GetResponseStream())
using (StreamReader reader = new StreamReader(stream))
{
    string responseText = reader.ReadToEnd();
    Console.WriteLine("Response from server:");
    Console.WriteLine(responseText);
}
`}
                      </code></pre>
                    </CodeSnippetTab>
                    <CodeSnippetTab title="Python">
                      <pre><code>
{`import requests

ip_address = '8.8.8.8'  # Replace with the IP address you want to lookup
endpoint = f'http://your.endpoint/api/v1/lookup/{ip_address}'
headers = {
    'Authorization': 'Bearer YOUR_AUTH_TOKEN'
}

response = requests.get(endpoint, headers=headers)

if response.status_code == 200:
    print('Response from server:')
    print(response.json())
else:
    print(f'Error: {response.status_code}')
`}
                      </code></pre>
                    </CodeSnippetTab>
                    <CodeSnippetTab title="Curl">
                      <pre><code>
{`#!/bin/bash

# Replace with the IP address you want to lookup
ip_address="8.8.8.8"

# Replace with your actual authentication token
auth_token="YOUR_AUTH_TOKEN"

# Endpoint URL
endpoint="http://your.endpoint/api/v1/lookup/$ip_address"

# Perform GET request with curl
curl -X GET "$endpoint"
     -H "Authorization: Bearer $auth_token"
     -H "Content-Type: application/json"
`}
                      </code></pre>
                    </CodeSnippetTab>
                  </CodeSnippet>

 Always ensure to keep your API key secure and restrict its usage to enhance security.
              </div>
        </>
    );
};

export const GetApiIpLookup = () => {
  return (
    <>
        <div className="services-details-overview-content">
            <h3>IP Lookup</h3>
            <p>
              Web Design and Development, consectetur adipiscing elit.
              Cursus molestie sit lectus eu vel platea tellus amet quam.
              Metus, ultrices velit quis nisi varius. Parturient gravida
              odio felis sit at arcu habitant tristique. Malesuada ac mi
              elit, scelerisque felis eu pharetra. Maecenas eget lorem nisl
              lectus arcu ac commodo arcu elit. Tempus condimentum aenean
              neque id vulputate adipiscing facilisis etiam.
            </p>
            <p>
              Proin erat viverra ac ac tellus. Odio porttitor blandit vitae,
              sagittis turpis ut diam lobortis lacus. Mauris ipsum sed
              ornare varius. Dui, orci sed rutrum fames metus at sit. A
              neque at eu eget vestibulum tortor. Volutpat imperdiet sit
              nulla phasellus morbi convallis id pretium volutpat. Sit amet
              urna pharetra, pharetra, elementum. Consectetur diam dignissim
              vestibulum lorem at. Eget facilisis ut elementum pharetra.
              Nibh dictum fermentum id suscipit porta orci a amet et. Non
              nunc, suspendisse amet amet ut odio est tristique nec. Neque,
              pretium elementum at tellus aliquam. Integer eu rhoncus nulla
              lectus nibh felis pretium ante. Nam enim sit sed accumsan
              proin tellus nisi adipiscing quam.
            </p>
            <p>
              Nunc, mauris ut in vestibulum. Consectetur phasellus ultrices
              fusce nibh justo, venenatis, amet. Lectus quam in lobortis
              nunc nisi, ut diam proin faucibus. Hendrerit viverra in congue
              enim feugiat lacus amet sed. Ipsum sed magnis tortor, vel.
              Mauris mi eget ac morbi. Enim conseuat viverra odio commodo
              vitae most designer and developer.
            </p>

            <ul className="overview-list">
              <li>
                <i className="ri-check-line"></i> Nunc, mauris ut in
                vestibulum. Consectetur phasellus ultrices fusce nibh justo,
                venenatis, amet. Lectus quam in lobortis
              </li>
              <li>
                <i className="ri-check-line"></i> Most of the designer are
                very creative to do something , mauris ut in vestibulum.
                Consectetur phasellus ultrices fusce nibh justo, venenatis,
                amet. Lectus quam in lobortis
              </li>
              <li>
                <i className="ri-check-line"></i> There are two thing is
                very important in Consectetur phasellus ultrices fusce nibh
                justo, venenatis, amet. Lectus quam in lobortis
              </li>
              <li>
                <i className="ri-check-line"></i> Web design and development
                very creative to do something , mauris ut in vestibulum.
                Consectetur phasellus ultrices fusce nibh justo, venenatis,
                amet to all design and development.
              </li>
            </ul>

            <div className="overview-image">
              <img
                src="/images/services-details/services-details-1.jpg"
                alt="image"
              />

              {/* Shape Images */}
              <div className="shape-1">
                <img
                  src="/images/services-details/shape-1.png"
                  alt="image"
                />
              </div>
              <div className="shape-2">
                <img
                  src="/images/services-details/shape-2.png"
                  alt="image"
                />
              </div>
              <div className="shape-3">
                <img
                  src="/images/services-details/shape-3.png"
                  alt="image"
                />
              </div>
            </div>
          </div>
    </>
);
};

export const GetApiDatabasePinning = () => {
  return (
    <>
        <div className="services-details-overview-content">
            <h3>Database Pinning</h3>
            <p>
              Web Design and Development, consectetur adipiscing elit.
              Cursus molestie sit lectus eu vel platea tellus amet quam.
              Metus, ultrices velit quis nisi varius. Parturient gravida
              odio felis sit at arcu habitant tristique. Malesuada ac mi
              elit, scelerisque felis eu pharetra. Maecenas eget lorem nisl
              lectus arcu ac commodo arcu elit. Tempus condimentum aenean
              neque id vulputate adipiscing facilisis etiam.
            </p>
            <p>
              Proin erat viverra ac ac tellus. Odio porttitor blandit vitae,
              sagittis turpis ut diam lobortis lacus. Mauris ipsum sed
              ornare varius. Dui, orci sed rutrum fames metus at sit. A
              neque at eu eget vestibulum tortor. Volutpat imperdiet sit
              nulla phasellus morbi convallis id pretium volutpat. Sit amet
              urna pharetra, pharetra, elementum. Consectetur diam dignissim
              vestibulum lorem at. Eget facilisis ut elementum pharetra.
              Nibh dictum fermentum id suscipit porta orci a amet et. Non
              nunc, suspendisse amet amet ut odio est tristique nec. Neque,
              pretium elementum at tellus aliquam. Integer eu rhoncus nulla
              lectus nibh felis pretium ante. Nam enim sit sed accumsan
              proin tellus nisi adipiscing quam.
            </p>
            <p>
              Nunc, mauris ut in vestibulum. Consectetur phasellus ultrices
              fusce nibh justo, venenatis, amet. Lectus quam in lobortis
              nunc nisi, ut diam proin faucibus. Hendrerit viverra in congue
              enim feugiat lacus amet sed. Ipsum sed magnis tortor, vel.
              Mauris mi eget ac morbi. Enim conseuat viverra odio commodo
              vitae most designer and developer.
            </p>

            <ul className="overview-list">
              <li>
                <i className="ri-check-line"></i> Nunc, mauris ut in
                vestibulum. Consectetur phasellus ultrices fusce nibh justo,
                venenatis, amet. Lectus quam in lobortis
              </li>
              <li>
                <i className="ri-check-line"></i> Most of the designer are
                very creative to do something , mauris ut in vestibulum.
                Consectetur phasellus ultrices fusce nibh justo, venenatis,
                amet. Lectus quam in lobortis
              </li>
              <li>
                <i className="ri-check-line"></i> There are two thing is
                very important in Consectetur phasellus ultrices fusce nibh
                justo, venenatis, amet. Lectus quam in lobortis
              </li>
              <li>
                <i className="ri-check-line"></i> Web design and development
                very creative to do something , mauris ut in vestibulum.
                Consectetur phasellus ultrices fusce nibh justo, venenatis,
                amet to all design and development.
              </li>
            </ul>

            <div className="overview-image">
              <img
                src="/images/services-details/services-details-1.jpg"
                alt="image"
              />

              {/* Shape Images */}
              <div className="shape-1">
                <img
                  src="/images/services-details/shape-1.png"
                  alt="image"
                />
              </div>
              <div className="shape-2">
                <img
                  src="/images/services-details/shape-2.png"
                  alt="image"
                />
              </div>
              <div className="shape-3">
                <img
                  src="/images/services-details/shape-3.png"
                  alt="image"
                />
              </div>
            </div>
          </div>
    </>
);
};
