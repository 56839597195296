import React, { useState } from 'react';
import { Link } from "react-router-dom";

const ResourceSideBar = () => {
  return (
    <>
      <div className="services-details-information">
        <ul className="services-list">
          <li>
            <Link to="/resources">
                <span>Overview</span>
            </Link>
          </li>
          <li>
            <CollapsibleListItem title="IP Geolocation API">
                <li>
                  <Link to="/resources/api-authentication">
                    <span>Authentication</span>
                  </Link>
                </li>
                <li>
                  <Link to="/resources/api-iplookup">
                    <span>IP Lookup</span>
                  </Link>
                </li>
                <li>
                  <Link to="/resources/api-databasepinning">
                    <span>Database Pinning</span>
                  </Link>
                </li>
              </CollapsibleListItem>
          </li>
          <li>
            <CollapsibleListItem title="IP Lookup Database">
                <li>
                  <Link to="/resources/ipdatabase-sdk">
                    <span> Java Reader SDK</span>
                  </Link>
                </li>
                <li>
                  <Link to="/resources/ipdatabase-lookup">
                    <span>IP Lookup</span>
                  </Link>
                </li>
            </CollapsibleListItem>
          </li>
          <li>
           <CollapsibleListItem title="IP Proxy Database">
              <li>
                <Link to="/resources/dbproxy-sdk">
                  <span> Java Reader SDK</span>
                </Link>
              </li>
              <li>
                <Link to="/resources/dbproxy-lookup">
                  <span>IP Proxy Lookup</span>
                </Link>
              </li>
            </CollapsibleListItem>
          </li>
          <li>
            <CollapsibleListItem title="IP Atlas CLI">
                <li>
                  <Link to="/resources/cli-installation">
                    <span>CLI Installation</span>
                  </Link>
                </li>
                <li>
                  <Link to="/resources/cli-commands">
                    <span>Commands</span>
                  </Link>
                </li>
            </CollapsibleListItem>
          </li>
        </ul>

        <div className="services-contact-info">
          <h3>Let's Make Something Amazing Together!</h3>

          {/* <div className="info-box-one">
            <i className="ri-customer-service-line"></i>
            <a href="tel:3128959800">(312) 895-9800</a>
          </div> */}

          <div className="info-box-two">
            <i className="ri-earth-line"></i>
            <a href="mailto:support@ipatlas.net">support@ipatlas.net</a>
          </div>
        </div>
      </div>
    </>
  );
};

const CollapsibleListItem = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <li>
      <span onClick={handleToggle} className={`caret ${isOpen ? 'caret-down' : ''}`}>
        {title}
      </span>
      <ul className={`nested ${isOpen ? 'active' : ''}`}>
        {children}
      </ul>
    </li>
  );
};

export default ResourceSideBar;
