import React, { useState, useEffect } from "react";
import BtnStandard from "../Buttons/BtnStandard/BtnStandard";
import SideModal from "../Modals/SideModal/SideModal";
import { Link } from "react-router-dom";


const Navbar = () => {
  const [menu, setMenu] = useState(true);

  const toggleNavbar = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
  });

  // Sidebar Modal
  const [isActiveSidebarModal, setActiveSidebarModal] = useState("false");
  const handleToggleSidebarModal = () => {
    setActiveSidebarModal(!isActiveSidebarModal);
  };

  const classOne = menu
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show";
  const classTwo = menu
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  return (
    <>
      <div id="navbar" className="navbar-area">
        <div className="main-navbar">
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Link to="/" className="navbar-brand">
                <img src="../images/logo.png"alt="logo" />
              </Link>

              <button
                onClick={toggleNavbar}
                className={classTwo}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button>

              <div className={classOne} id="navbarSupportedContent">
                <ul className="navbar-nav m-auto">
                  <li className="nav-item">
                    <Link to="/">
                      <span className="nav-link">Home</span>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <span className="nav-link">Services</span>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <Link to="/ip-lookup-database">
                        <span className="nav-link">IP Lookup Database</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/ip-proxy-database">
                        <span className="nav-link">IP Proxy Database</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/ip-geolocation-api">
                        <span className="nav-link">IP Geolocation API</span>
                        </Link>
                      </li>
                  </ul>
                  </li>

                  <li className="nav-item">
                    <Link to="/resources/">
                      <span className="nav-link">Resources</span>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/pricing/">
                      <span className="nav-link">Pricing</span>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/contact/">
                      <span className="nav-link">Contact</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="others-options d-flex align-items-center">
                <div className="option-item">
                  <BtnStandard
                    btnClass="standard-btn demo-btn"
                    onClick={handleToggleSidebarModal}
                  >
                    Test Your IP
                  </BtnStandard>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <SideModal
        activeModal={isActiveSidebarModal}
        closeModal={handleToggleSidebarModal}
      ></SideModal>
    </>
  );
};

export default Navbar;
