import React from 'react';

const CodeSnippetTab = ({ children }) => {
  return (
    <div className="tab-panel">
        {children}
    </div>
  );
};

export default CodeSnippetTab;
