import React from "react";
import AOS from "aos";
import "../node_modules/aos/dist/aos.css";
import "./styles/bootstrap.min.css";
import "./styles/animate.min.css";
// import "./styles/remixicon.css";
// import "react-accessible-accordion/dist/fancy-example.css";
// import "../node_modules/react-modal-video/css/modal-video.min.css";
import "react-18-image-lightbox/style.css";
// import "react-tabs/style/react-tabs.css";
// import "swiper/css";
// import "swiper/css/bundle";

// Global Style
import "./styles/style.scss";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import PageContent from './pages/PageContent';

const firebaseConfig = {
  apiKey: "AIzaSyAdF_zruxYuSfKCM7nGG3OG0r2hLxAoMOg",
  authDomain: "ipatlas.firebaseapp.com",
  projectId: "ipatlas",
  storageBucket: "ipatlas.appspot.com",
  messagingSenderId: "969562750191",
  appId: "1:969562750191:web:48f7dd5d952881b3ff6f1a",
  measurementId: "G-E9RRNL391V"
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);

function App() {
  React.useEffect(() => {
    AOS.init();
  }, []);


  return (
    <PageContent />
  );
}

export default App;
