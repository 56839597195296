import React from "react";

const MobileApp = () => {
    return (
    <>
        <a href='https://play.google.com/store/apps/details?id=net.maguard.geolocation&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank" rel="noopener noreferrer">
            <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
        </a>
    </>)
};

export default MobileApp;