import React from "react";
import { Link } from "react-router-dom";
import Arrow from "remixicon-react/ArrowRightLineIcon";



function Servis({ title, text, classColor, icon }) {
  

  
  return (
    <div
      className="col-lg-4 col-md-6"
      data-aos="fade-up"
      data-aos-delay="100"
      data-aos-duration="1200"
      data-aos-once="false"
    >
      <div className="single-services">
        <div className={classColor}>
          <i></i>
        </div>
        <h3>
          <Link to="/services-details">
            <span>{title}</span>
          </Link>
        </h3>
        <p>{text}</p>

        <Link to="/services-details">
          <span className="services-btn">
            Read More{" "}
            <i>
              <Arrow size={15}></Arrow>
            </i>
          </span>
        </Link>
      </div>
    </div>
  );
}

export default Servis;
