import React, { useState } from 'react';

const CodeSnippet = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0); // State to track active tab index

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="code-snippet">
      <div className="tab-header">
        {children.map((child, index) => (
          <div
            key={index}
            className={`tab ${index === activeTab ? 'active' : ''}`}
            onClick={() => handleTabClick(index)}
          >
            {child.props.title}
          </div>
        ))}
      </div>
      
      {/* Render tab content based on activeTab state */}
      <div className="tab-content">
        {children[activeTab]}
      </div>
    </div>
  );
};

export default CodeSnippet;
