import React from "react";
import { Link } from "react-router-dom";

function FooterWidget({ widget }) {
  return (
    <div
      className="col-lg-2 col-sm-6 col-md-3"
      data-aos="fade-in"
      data-aos-delay="200"
      data-aos-duration="1200"
      data-aos-once="false"
    >
      <div className="single-footer-widget">
        <h3>{widget.title}</h3>

        <ul className="footer-links-list">
          {widget.content.map((elem) => {
            return (
              <li key={elem.id}>
                <Link to={elem.link}>{elem.name}</Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default FooterWidget;
