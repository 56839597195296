import React from "react";
import { Link } from "react-router-dom";
import ArrowRight from "remixicon-react/ArrowRightLineIcon";

function BtnLink({ children, link }) {
  return (
    <>
      <Link to={link} className="default-btn">
        {children}
        <ArrowRight className="default-btn__icon" size={20} />
        <span></span>
      </Link>
    </>
  );
}

export default BtnLink;

// how to use
// <BtnLink link="/">Button text</BtnLink>