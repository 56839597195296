import React from "react";
import Navbar from "../components/_App/Navbar/Navbar";
import MainBanner from "../components/HomePage/HomeBanner/MainBanner";
import FeaturesCard from "../components/HomePage/FeaturesCard/FeaturesCard";
import Footer from "../components/_App/Footer/Footer";
import AboutUsContent from "../components/HomePage/AboutUsContent/AboutUsContent";
import Services from "../components/HomePage/Services/Services";
import TechSupport from "../components/HomePage/TechSupport/TechSupport";

function HomePage() {
  return (
    <>
      <Navbar />

      <MainBanner />

      <FeaturesCard />

      <Services />

      <AboutUsContent />

      <TechSupport />

      <Footer />
    </>
  );
}

export default HomePage;
