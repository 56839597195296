import React, { useEffect } from "react";
import { useState } from "react";

function TabsDefault({ ipData }) {
  const [tabToogle, setTabToogle] = useState(1);
  const [locationInfo, setLocalInfo] = useState([]);
  const [provider, setProvider] = useState([]);

  const handleToogle = (index) => {
    setTabToogle(index);
  };

  //const obj = useMemo(() => ({ ipData }), [ipData]);

  useEffect(() => {
    if (ipData != null) {
      setLocalInfo(Object.entries(ipData.locationInfo));
      setProvider(Object.entries(ipData.internetServiceProvider));
    }
  }, [ipData]);

  function camelCaseToNormalString(camelCase) {
    let normalString = camelCase.replace(/([a-z])([A-Z])/g, "$1 $2");
    normalString = normalString.toLowerCase();
    normalString = normalString.charAt(0).toUpperCase() + normalString.slice(1);
    return normalString;
  }

  return (
    <div className="tabs-container container">
      <div className="tabs-title">
        <div
          className={tabToogle === 1 ? "tab-name activ-tab" : "tab-name"}
          onClick={() => handleToogle(1)}
        >
          Location info
        </div>
        <div
          className={tabToogle === 2 ? "tab-name activ-tab" : "tab-name"}
          onClick={() => handleToogle(2)}
        >
          Service provider
        </div>
      </div>
      <div className="tabs-body">
        <div className={tabToogle === 1 ? "activ-content" : "hide-content"}>
          <table className="ipDataTable">
            <tbody>
              {locationInfo.map((elem) => {
                return (
                  <tr className="ipDataTable__row" key={elem[0]}>
                    <td className="ipDataTable__row__cell">
                      {camelCaseToNormalString(elem[0])}:
                    </td>
                    <td className="ipDataTable__row__cell">{elem[1]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className={tabToogle === 2 ? "activ-content" : "hide-content"}>
          <table className="ipDataTable">
            <tbody>
              {provider.map((elem) => {
                return (
                  <tr className="ipDataTable__row" key={elem[0]}>
                    <td className="ipDataTable__row__cell">
                      {camelCaseToNormalString(elem[0])}:
                    </td>
                    <td className="ipDataTable__row__cell">{elem[1]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TabsDefault;
