import React from "react";
import Navbar from "../components/_App/Navbar/Navbar";
import FeaturesCard from "../components/HomePage/FeaturesCard/FeaturesCard";
import Footer from "../components/_App/Footer/Footer";
import Services from "../components/HomePage/Services/Services";
import PageBanner from "../components/_App/PageBanner/PageBanner";

function PricingPage() {
  return (
    <>
      <Navbar />

      <PageBanner
        pageTitle="Pricing"
        homePageUrl="/"
        homePageText="Home"
        activePageText="Pricing"
      />

      <FeaturesCard />

      <Services />

      <Footer />
    </>
  );
}

export default PricingPage;