import React from "react";
import { useId } from "react";
import Servis from "./Servis.jsx/Servis";

const Services = () => {
  const servicesContent = [
    {
      id: useId(),
      title: "Country, Region, City and Continent",
      text: `
Our IP location mapping data includes detailed information such as country, region, capital city,
 , continent, calling code, domain name, currency and population, providing comprehensive insights into the geographical origin of IP addresses.
      `,
      icon: "QuillPenLineIcon",
      classColor: "icon",
      link: "/resources-data",
    }, 
    {
      id: useId(),
      title: "Internet Service Provider",
      text: `Our IP location mapping data also includes the Internet Service Provider (ISP),
       offering valuable insights into the network infrastructure associated with each IP address.`,
      icon: "PieChartLineIcon",
      classColor: "icon bg-36CC72",
      link: "/resources-data",
    },
    {
      id: useId(),
      title: "Proxy & VPN",
      text: `Our IP location mapping data extends to include details about proxies and VPNs, providing insights into the use of these technologies and their associated network attributes.`,
      icon: "ArrowRightLineIcon",
      classColor: "icon bg-FF414B",
      link: "/resources-data",
    }
  ];
  return (
    <>
      <div className="services-area pt-70 pb-70">
        <div className="container">
          <div className="section-title">
            <h2>Data We Provide</h2>
            <p>
              We deliver a wide range of data associated with the IP address. Data may vary between the API and databases.
              For example, a country flag is only available in API.
            </p>
          </div>

          <div className="row justify-content-center">
            {servicesContent.map((item)=>{
             return <Servis key={item.id} title={item.title} text={item.text} classColor={item.classColor} icon={item.icon}></Servis>
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
