import React from "react";
import BtnLink from "../../_App/Buttons/BtnLink/BtnLink";


const AboutUsContent = () => {
  return (
    <>
      <div className="experiences-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="experiences-item">
                <div className="experiences-content">
                  <div className="tag">
                    <img src="/images/experiences/tag-icon.png" alt="Building mutual trust visual graphic" />
                  </div>
                  <h3>Digital automation, safety, and security</h3>
                  <p>
                   Enhance web security and gain valuable insights into your visitors with advanced IP geolocation services.
                  </p>
                </div>

                <div className="experiences-inner-content">
                  <img src="/images/experiences/img1.png" alt="Continues growth" />
                  <p>
                  Our goals for enhanced security include regular maintenance, continuous improvements, and offering new features to our customers.
                  </p>
                </div>

                <div className="experiences-inner-content">
                  <img src="/images/experiences/img2.png" alt="Data accurency" />
                  <p>
                  We strive to provide our customers with the largest possible database and with the highest possible data accuracy.
                  </p>
                </div>

                <div className="experiences-inner-content">
                  <img src="/images/experiences/img3.png" alt="Reliability and policies" />
                  <p>
                  We don't require our customers to agree to complex and restrictive policies. Our task is to provide you with the most accurate data for a given IP address,
                   and your task is to utilize that data in your business as you see fit.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div 
                className="experiences-image"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="1200"
                data-aos-once="false"
              >
                <img src="/images/experiences/experiences-1.png" alt="Experiences" />
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default AboutUsContent;
