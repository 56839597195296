import React from "react";
import FooterWidget from "./FooterWidget/FooterWidget";
import MobileApp from "../MobileApp/MobileApp";
import { useId } from "react";
import { Link } from "react-router-dom";

import FacebookIcon from "remixicon-react/FacebookLineIcon";
import TwitterX from "remixicon-react/TwitterFillIcon";
import Linkedin from "remixicon-react/LinkedinLineIcon";
import Messenger from "remixicon-react/MessengerLineIcon";
import ServiceLine from "remixicon-react/CustomerServiceLineIcon";
import GlobalLine from "remixicon-react/GlobalLineIcon";
import MapPin from "remixicon-react/MapPinLineIcon"

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const footerLinks = [
    {
      id: useId(),
      title: "Quick links",
      content: [
        { name: "Services", link: "/services", id: useId() },
        { name: "Privacy policy", link: "/privacy-policy", id: useId() },
      ],
    },
    {
      id: useId(),
      title: "Services",
      content: [
        { name: "Pricing", link: "/pricing", id: useId() },
        {
          name: "GeoIP Data Corection",
          link: "https://forms.gle/szvNVjMBfVGBhxqD7",
          id: useId(),
        },
      ],
    },
    {
      id: useId(),
      title: "About us",
      content: [
        { name: "Contact", link: "/contact", id: useId() },
        { name: "Cloud Computing", link: "/service", id: useId() },
      ],
    },
  ];
  return (
    <footer className="footer-area pt-100">
      <div className="container">
        <div className="row justify-content-center">
          <div
            className="col-lg-3 col-sm-6 col-md-6"
            data-aos="fade-in"
            data-aos-delay="100"
            data-aos-duration="1200"
            data-aos-once="false"
          >
            <div className="single-footer-widget">
              <div className="widget-logo">
                <Link to="/">
                  <img src="/images/logo.png" alt="logo" />
                </Link>
              </div>
              <p>
              IP Geolocation API, IP Lookup and Proxy Databases
              </p>

              <ul className="widget-info">
                <li>
                  <i >
                    <ServiceLine  size={18}/>
                  </i>
                  <a href="tel:381">381</a>
                </li>

                <li>
                  <i className="ri-global-line">
                    <GlobalLine size={18}/>
                  </i>
                  <a href="support@ipatlas.net">support@ipatlas.net</a>
                </li>

                <li>
                  <i className="ri-map-pin-line">
                    <MapPin size={18}/>
                  </i>
                  Serbia
                </li>
              </ul>
            </div>
          </div>
          {/* Render Widget from footerLinks */}
          {footerLinks.map((elem) => {
            return <FooterWidget widget={elem} key={elem.id} />;
          })}
          <div
            className="col-lg-3 col-sm-12 col-md-6"
            data-aos="fade-in"
            data-aos-delay="500"
            data-aos-duration="1200"
            data-aos-once="false"
          >
            <div className="single-footer-widget">
              <ul className="widget-social">
                <li>
                  <a href="https://www.facebook.com/" target="_blank" rel="noreferrer" >
                    <i>
                      <FacebookIcon size={18} />
                    </i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/" target="_blank" rel="noreferrer" >
                    <i>
                      <TwitterX  size={18}/>
                    </i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/" target="_blank" rel="noreferrer" >
                    <i>
                      <Linkedin size={18}/>
                    </i>
                  </a>
                </li>
                <li>
                  <a href="https://www.messenger.com/" target="_blank" rel="noreferrer" >
                    <i>
                      <Messenger  size={18}/>
                    </i>
                  </a>
                </li>
              </ul>
              <MobileApp />
            </div>
          </div>
        </div>
      </div>

      <div className="copyright-area">
        <div className="container">
          <div className="copyright-area-content">
            <p>
              Copyright © {currentYear} IpAtlas. All Rights Reserved by{" "}
              <a href="/" target="_blank">
                IpAtlas
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
