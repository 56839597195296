import React from "react";

function BtnStandard({ children, btnClass, onClick }) {
  return (
    <button className={btnClass} onClick={onClick}>
      {children}
      <span></span>
    </button>
  );
}

export default BtnStandard;
