import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./HomePage";
import ServicesPage from "./ServicesPage";
import ContactPage from "./ContactPage";
import ResourcesPage from "./ResourcesPage";
import PricingPage from "./PricingPage";

function PageContent() {
  return (
    <div className="pageContent">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/resources/*" element={<ResourcesPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/contact" element={<ContactPage/>} />
      </Routes>
    </div>
  );
}

export default PageContent;
