import React from "react";
import { Link } from "react-router-dom";

const featuresData = [
  {
    image: "/images/features/features-2.png",
    imgAlt: "IP Lookup service icon",
    title: "IP Lookup Database",
    shortText:
      "Customize content for your target audience with precise IP details from the IP Lookup Database. Utilize accurate location and ISP information for targeted marketing, fraud prevention, personalized content delivery, digital rights management, regulatory compliance, and enhanced security.",
    btnText: "Read More",
    btnLink: "/ip-lookup-database",
    aosDelay: "100",
  },
  {
    image: "/images/features/features-1.png",
    imgAlt: "IP Proxy service icon",
    title: "IP Proxy Database",
    shortText:
      "Detect and manage proxy servers with the IP Proxy Database. Gain comprehensive insights to enhance security, control access, and optimize your network for better performance.",
    btnText: "Read More",
    btnLink: "/ip-proxy-database",
    aosDelay: "200",
  },
  {
    image: "/images/features/features-3.png",
    imgAlt: "API Service small icon",
    title: "IP Geolocation API",
    shortText:
      "The API delivers location details, ISP information, and more, seamlessly integrating geolocation data into online applications without the need for downloading databases. Ideal for real-time insights, targeted marketing, and enhanced user experiences.",
    btnText: "Read More",
    btnLink: "/ip-geolocation-api",
    aosDelay: "300",
  },
];

function FeaturesCard() {
  return (
    <>
      <div className="features-area pt-70 pb-70">
        <div className="container">
          <div className="row justify-content-center">
            {featuresData &&
              featuresData.slice(0, 3).map((value, i) => (
                <div
                  className="col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay={value.aosDelay}
                  data-aos-duration="1200"
                  data-aos-once="false"
                  key={i}
                >
                  <div className="single-features">
                    <Link to={value.btnLink}>
                      <img src={value.image} alt={value.imgAlt} />
                    </Link>

                    <h3>
                      <Link to={value.btnLink}>{value.title}</Link>
                    </h3>
                    <p>{value.shortText}</p>

                    <div className="features-btn">
                      <Link to={value.btnLink}>
                        <span className="default-btn">
                          {value.btnText}
                          <i className="ri-arrow-right-line"></i>
                          <span></span>
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default FeaturesCard;
