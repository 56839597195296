import React from "react";
import TabsDefault from "../../Tabs/TabsDefault";
import MobileApp from "../../MobileApp/MobileApp";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CloseLine from 'remixicon-react/CloseLineIcon';

function SideModal({ activeModal, closeModal }) {
  const [ipData, setIpData] = useState(null);
  useEffect(() => {
    const data = async () => {
      const url = "https://ipinstance.azurewebsites.net/api/v1/lookup";

      const apiKey = "NTZIWlkzZWctZ0Y4MGxZQXotdEZiRS8vZG0tL1ZBPS0=";
      const userAgent = "ipatlas-fronend";

      const header = {
        authorization: `Bearer ${apiKey}`,
        "x-user-agent": userAgent,
      };

      try {
        const response = await fetch(url, {
          method: "GET",
          headers: header,
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const dataRes = await response.json();

        setIpData(dataRes);
      } catch (error) {
        console.log(error);
      }
    };
    data();
  }, []);

  return (
    <div className={`sidebarModal modal right ${activeModal ? "" : "show"}`}>
      <div className="modal-dialog">
        <div className="modal-content">
          <button type="button" className="close" onClick={closeModal}>
            <CloseLine/>
          </button>
          <div className="modal-body">
            <div className="title">
              <Link to="/" className="d-inline-block">
                <img src="/images/logo.png" alt="logo" />
              </Link>
            </div>
            <div className="sideModal-content">
              <span className="ipBaner">
                Your IP address:
                {ipData != null ? ` ${ipData.requestedIPAddress}` : ""}
              </span>
              <span className="ipBaner">
                Proxy:
                {ipData != null ? ` ${ipData.proxy}` : ""}
              </span>

              <TabsDefault ipData={ipData} />
              <MobileApp />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideModal;
