import React from "react";
import Navbar from "../components/_App/Navbar/Navbar";
import Footer from "../components/_App/Footer/Footer";
import TechSupport from "../components/HomePage/TechSupport/TechSupport";
import PageBanner from "../components/_App/PageBanner/PageBanner";
import ContactInfo from "../components/ContactPage/ContactInfo/ContactInfo";

function ContactPage() {
  return (
    <>
      <Navbar />

      <PageBanner
        pageTitle="Contact"
        homePageUrl="/"
        homePageText="Home"
        activePageText="Contact"
      />

      <ContactInfo />

      <TechSupport />

      <Footer />
    </>
  );
}

export default ContactPage;