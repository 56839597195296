import React from "react";
import { Link } from "react-router-dom";

const PageBanner = ({
  pageTitle,
  homePageUrl,
  homePageText,
  activePageText,
}) => {
  return (
    <>
      <div className="page-banner-area">
        <div className="container">
          <div className="page-banner-content">
            <h2>{pageTitle}</h2>
            <ul>
              <li>
                <Link to={homePageUrl}>
                  <span>{homePageText}</span>
                </Link>
              </li>
              <li className="active">
                {activePageText}
              </li>
            </ul>
          </div>
        </div>

        {/* Shape Images */}
        <div className="page-banner-shape-4">
          <img src="/images/page-banner/shape-4.png"  alt="Small blue shape floating around"  />
        </div>
      </div>
    </>
  );
};

export default PageBanner;
