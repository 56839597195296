import React from "react";
import Navbar from "../components/_App/Navbar/Navbar";
import Footer from "../components/_App/Footer/Footer";
import PageBanner from "../components/_App/PageBanner/PageBanner";
import ResourcesDetailsContent from "../components/HomePage/Resources/ResourceContent";

function ResourcesPage() {
  return (
    <>
      <Navbar />

      <PageBanner
        pageTitle="Resources"
        homePageUrl="/"
        homePageText="Home"
        activePageText="Resources"
      />

      <ResourcesDetailsContent />

      <Footer />
    </>
  );
}

export default ResourcesPage;