import React from "react";
import FaqAccordion from "../FaqAccordion/FaqAccordion";

const ContactInfo = () => {
  return (
    <>
      <div className="contact-info-area pb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-12">
              <div className="contact-address">
                <h3>Contact Us:</h3>
                <p>
                We're always here to assist you! Whether you have questions, suggestions, or just want to say hello, 
                don't hesitate to drop us a line at email. We look forward to hearing from you and will do our best to respond promptly.
                </p>

                <ul className="address-info">
                  {/* <li>
                    <i className="ri-customer-service-line"></i>
                    <a href="tel:381">Follow Us On Twitter or Reddit</a>
                  </li> */}
                  <a href="mailto:support@ipatlas.net">support@ipatlas.net</a>
                  {/* <li>
                    <a href="mailto:support@ipatlas.net">support@ipatlas.net</a>
                    <i className="ri-global-line"></i>
                  </li> */}
                </ul>
                {/* 
                <ul className="address-social">
                  <li>
                    <a href="https://www.facebook.com/" target="_blank">
                      <i className="ri-facebook-fill"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/" target="_blank">
                      <i className="ri-twitter-fill"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/" target="_blank">
                      <i className="ri-linkedin-fill"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.messenger.com/" target="_blank">
                      <i className="ri-messenger-fill"></i>
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>

            <div className="col-lg-8 col-md-12">
            <FaqAccordion/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactInfo;
